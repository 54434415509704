export const NOTIFICATION_STATUS = {
	unread: 'unread',
	read: 'read',
}

export const NOTIFICATION_EVENT = {
	job_matches: 'job_matches',
	job_alert: 'job_alert',
	job_rating: 'job_rating',
	timecard_submission: 'timecard_submission',
	Job: 'Job',
	assignment_check_out: 'assignment_check_out',
	new_feed: 'news_feed_and_resource_match',
	credential_events: ['credential_expired_soon', 'credential_new_request', 'credential_incompleted'],
	new_message: 'new_message',
	campaign: 'campaign',
}
