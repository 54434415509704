import { action, computed, observable, store } from '~/common/mobx.decorator'
import { authStore, masterStore } from '~/stores'
import { isEmpty, isNil, omit } from 'lodash'
import { careFindJobStore } from '~/features/care-find-job/care-find-job.store'

@store()
class JobAlertStore {
	@observable activeJobEdit = {}
	@observable MODE_LIST = {
		view: 'view',
		create: 'create',
		edit: 'edit',
	}
	@observable mode = 'view'
	@observable showDialogRemove = false
	@observable activeRemoveSaveSearch = {}
	@observable showDialogFillInfoJobAlertAno = false
	@observable showJobAlertDrawer = false
	@observable showDialogSignup = false
	@observable showAllFilterDrawer = false
	@observable activeCheckAllProfession = []

	@action
	setActiveCheckAllProfession = (item) => {
		this.activeCheckAllProfession = item
	}

	@computed
	get activeJobAlertEditInfo() {
		return this.activeJobEdit
	}

	@computed
	get getModeField() {
		return this.mode
	}

	@computed
	get activeJobAlertItem() {
		const item = careFindJobStore.listSaveSearch?.find((item) => item.isActive === true)
		const dataItem = authStore.id
			? {
					...item?.filterCondition,
					...item,
			  }
			: omit(
					{
						...item,
						...item?.savedFilter,
						filterCondition: item?.filterConditions,
					},
					['savedFilter', 'filterConditions']
			  )

		return !isNil(careFindJobStore.listSaveSearch?.find((item) => item.isActive === true)) ? dataItem : {}
	}

	@computed
	get specialtiesOptions() {
		const specialties = masterStore?.specialties
		if (isEmpty(specialties)) {
			return []
		}

		let options = []
		options = specialties?.map((item) => ({
			value: item.skill_name,
			label: item.long_name,
			has_checklist: item.has_checklist,
		}))
		return options
	}

	@action
	setShowDialogRemove = (value) => {
		this.showDialogRemove = value
	}
	@action
	setMode = (value) => {
		this.mode = value
		return this.mode
	}

	@action
	setActiveRemoveSaveSearch = (value) => {
		this.activeRemoveSaveSearch = value
	}
	@action
	getLabelFromValue = (value) => {
		const { listSpecialtyName } = masterStore
		return listSpecialtyName?.find((item) => item.value === value)?.label
	}
	@action
	setActiveJobEdit = (value) => {
		if (!!value) {
			this.activeJobEdit = value
		}
	}

	@action
	handleRemoveAnoItem = (item) => {
		const data = careFindJobStore.listSaveSearch.filter((i) => i.id !== item.id)
		careFindJobStore.listSaveSearch = data
		window.localStorage.setItem('saveSearchAno', JSON.stringify(data))
		if (careFindJobStore?.listSaveSearch?.length === 0) {
			window.localStorage.removeItem('lastSearch')
		}

		this.setShowDialogRemove(false)
		this.setMode(this.MODE_LIST.view)
	}
	@action
	handleClickUpdateSaveSearch = (item) => {}

	@action
	setShowDialogFillInfoJobAlertAno = (item) => {
		this.showDialogFillInfoJobAlertAno = item
	}

	@action
	setShowJobAlertDrawer = (item) => {
		this.showJobAlertDrawer = item
	}
	@action
	setShowDialogSignup = (item) => {
		this.showDialogSignup = item
	}
	@action
	setShowAllFilterDrawer = (item) => {
		this.showAllFilterDrawer = item
	}
}

export const jobAlertsStore = new JobAlertStore()
