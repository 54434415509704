import { apolloClient, CHANGE_PASSWORD_USER_MUTATION } from '~/common/apollo'
import { action, store } from '~/common/mobx.decorator'
import { notifyStore, authStore } from '~/stores'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { EVENT_CLICK_ID } from '~/common/tracking/event-click.constant'
import i18next from 'i18next'

@store()
class CommonChangePasswordStore {
	@action
	changePassword = async (variables, setErrors) => {
		logClickEvent(EVENT_CLICK_ID.clickConfirmChangePassBtn)
		try {
			const { data: response } = await apolloClient.mutate({ mutation: CHANGE_PASSWORD_USER_MUTATION, variables })
			console.debug(
				'🚀 ~ file: common-change-password.store.js:23 ~ CommonChangePasswordStore ~ changePassword= ~ response:',
				response
			)
			await authStore.changeToken(response.changePassword.authToken)

			return response?.changePassword?.success
		} catch (error) {
			if (error?.graphQLErrors?.[0]?.extensions?.error === 'invalid_password') {
				setErrors({ oldPassword: i18next.t('$ERRORS.INCORRERCT_CURRENT_PS') })
				return false
			}
			return notifyStore.error(error?.message)
		}
	}
}
export const commonChangePasswordStore = new CommonChangePasswordStore()
