import { gql } from '@apollo/client'
import {
	BADGED_FRAGMENT,
	FILE_FRAGMENT,
	ONBOARDING_PART_FRAGMENT,
	WORKER_ONBOARDING_PART_FRAGMENT,
} from '../apollo.fragment'

export const GET_WORKER_ONBOARDING_OVERVIEW_QUERY = gql`
	query getWorkerOnboardingQuery {
		workerOnboardingOverview {
			id
			message
			numberOfCompletedSteps
			percentageCompleted
			state
			companyOnboarding {
				companyId
				description
				id
				name
				onboardingPartsNumber
			}
			onboardingParts {
				workerOnboardingPart {
					...workerOnboardingPartFragment
				}
				...onboardingPartFragment
			}
		}
	}

	${ONBOARDING_PART_FRAGMENT}
	${WORKER_ONBOARDING_PART_FRAGMENT}
`

export const GET_NUMBER_COMPLETE_STEPS = gql`
	query getNumberCompleteSteps {
		workerOnboardingOverview {
			numberOfCompletedSteps
		}
	}
`

export const GET_WORKER_ONBOARDING_PART_QUERY = gql`
	query getWorkerOnboardingPart($id: String!) {
		onboardingPart(id: $id) {
			workerOnboardingPart {
				...workerOnboardingPartFragment
			}
		}
	}

	${WORKER_ONBOARDING_PART_FRAGMENT}
`

export const GET_WORKER_RESUMES_QUERY = gql`
	query workerResumesQuery($id: String!) {
		worker(id: $id) {
			resumes {
				...fileFragment
			}
		}
	}
	${FILE_FRAGMENT}
`

export const GET_WORKER_BADGED_QUERY = gql`
	query listWorkerBadges($after: String, $before: String, $first: Int, $last: Int) {
		workerBadges(after: $after, before: $before, first: $first, last: $last) {
			nodes {
				badge {
					badgeName
					badgeType
					id
				}
				id
				metadata
			}
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
			totalCount
		}
	}
`

export const GET_BADGED_QUERY = gql`
	query listBadges($after: String, $before: String, $first: Int, $last: Int) {
		badges(after: $after, before: $before, first: $first, last: $last) {
			nodes {
				...badgeDetails
			}
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
			totalCount
		}
	}
	${BADGED_FRAGMENT}
`
