import moment from 'moment'

const isNumeric = (input) => {
	const number = input.replace(',', '.') //to Universal format
	return !isNaN(parseFloat(number)) && isFinite(number)
}

const validateHHMMInput = (input) => {
	const min = input.slice(0, 2)
	const second = input.slice(2)

	return (
		isNumeric(min) &&
		parseInt(min, 10) >= 0 &&
		parseInt(min, 10) <= 23 &&
		(!second || (isNumeric(second) && parseInt(second, 10) >= 0 && parseInt(second, 10) <= 59))
	)
}

const formatHHMM = (input) => {
	return input.slice(0, 2) + ':' + input.slice(2)
}

const suggestTimeSet = (input) => {
	let result = new Set()

	if (input?.length === 4) {
		result.add(input)
		return result
	}

	if (input?.length > 4) {
		return result
	}

	for (let i = 0; i <= input?.length; ++i) {
		const value = input.slice(0, i) + '0' + input.slice(i)
		if (value?.length === 4) {
			result.add(value)
		} else {
			result = new Set([...result, ...suggestTimeSet(value)])
		}
	}

	return result
}

const suggestHHMMList = (input) => {
	const hhmm = input?.replace(/[^\d]/g, '')
	//
	return input.length > 0
		? Array.from(suggestTimeSet(hhmm))
				.filter((t) => validateHHMMInput(t))
				.map((t) => formatHHMM(t))
		: []
}

const VALUE_HHMM_LENGTH_FOUR = 4

const VALUE_HHMM_LENGTH_FIVE = 5
export { suggestHHMMList, validateHHMMInput, VALUE_HHMM_LENGTH_FIVE, VALUE_HHMM_LENGTH_FOUR }

export const convertSelectTimeToFutureDate = (time) => {
	if (!time) return

	const [timeNumber, timeUnit] = time.split('_')
	const currentDate = new Date()
	const validUnits = ['week', 'weeks', 'month', 'months']

	const unit = validUnits.includes(timeUnit) ? timeUnit : null

	if (!unit) return moment(currentDate).format('MMMM DD, YYYY')

	const futureDate = moment(currentDate).add(parseInt(timeNumber, 10), unit).toDate()
	return moment(futureDate).format('MMMM DD, YYYY')
}
