import React from 'react'
import {
	apolloClient,
	CHANGE_WORKER_PASSWORD,
	GET_CAMPAIGN_WOKER_DETAIL_QUERY,
	GET_CAMPAIGNS_QUERY,
	GET_WORKER_ONBOARDING_INFO,
	SIGNIN_WORKER_MUTATION,
} from '~/common/apollo'
import { RECAPTCHA_CLIENT_KEY } from '~/common/helpers'
import { action, store, toJS } from '~/common/mobx.decorator'
import { authStore, notifyStore } from '~/stores'
import { computed, observable } from 'mobx'
import { guidanceStore } from '~/components/guidance-components/guidance.store'
import { careHomeStore } from '../care-home/care-home.store'
import { isEmpty, isNil } from 'lodash'
import moment from 'moment'

export const ERROR_TYPES = {
	locked_access: 'locked_access',
	temp_password_expired: 'temp_password_expired',
}

@store()
class CommonSigninStore {
	@observable phoneVerifyAt = null
	@observable changePasswordRequired = false
	@observable workerID = ''
	@observable tempPassword = ''
	@observable tempToken = ''
	@observable tempWorker = {}
	@observable tempAccessToken = {}
	@observable campaigns = []
	@observable tempNotificationChannel = {}
	@observable hideCheckBoxConfirm = false
	@observable onboardingSteps = []
	@observable showCampaign = false
	@observable worker = {}

	@computed
	get activeCampaign() {
		// start_at =< current time < expired_at
		const activeCampaign = this.campaigns.find((item) => item.active === true)
		if (
			!isEmpty(activeCampaign) &&
			moment(activeCampaign?.startAt).isBefore(new Date()) &&
			moment(activeCampaign?.expiredAt).isAfter(new Date())
		) {
			return activeCampaign
		} else {
			return {}
		}
	}

	@computed
	get isRequiredChangePassword() {
		return this.changePasswordRequired
	}

	@action
	resetPage = () => {
		this.onboardingSteps = []
	}

	@action
	login = async (variables, { setErrors, setIsRequiredChangePassword }) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'signInWorker',
					})

					let updatedVariables = {
						recaptchaToken: token,
						...variables,
					}
					const {
						data: { signInWorker },
					} = await apolloClient.mutate({
						mutation: SIGNIN_WORKER_MUTATION,
						variables: updatedVariables,
						context: {
							clientName: 'public',
						},
					})

					//const { authToken, worker, notificationChannel } = signInWorker
					const { notificationChannel, authToken, worker } = signInWorker
					await authStore.handleAuthAnonymous(false)
					this.workerID = worker?.id

					if (worker?.user?.changePasswordRequired) {
						this.changePasswordRequired = true
						this.tempPassword = variables.password
						setIsRequiredChangePassword(true)
						this.tempToken = authToken
						this.tempWorker = worker
						this.tempNotificationChannel = notificationChannel
					} else {
						await authStore.changeProfile(worker, notificationChannel)
						await authStore.changeToken(authToken)
						await this.fetchOnboardingOverview()
						await this.fetchCampaigns()
						notifyStore.success('Successfully signed in')
						await this.pickSubmissionPopup(worker)
						if (
							!isEmpty(commonSigninStore.activeCampaign) &&
							isNil(this.activeCampaign?.workerCampaign?.webDismissAt)
						) {
							this.setShowCampaign(true)
						}
					}

					resolve()
				} catch (error) {
					const { graphQLErrors } = error

					if (graphQLErrors?.[0]?.extensions?.error === 'temp_password_expired') {
						notifyStore.error(
							<span
								dangerouslySetInnerHTML={{
									__html: `The password you entered has expired and is only valid for <strong style="color:#333335">72 hours.</strong>\n\nWe have sent a new temporary password to your email. Please check it and follow the instructions to reset your password.`,
								}}
							/>,
							{
								title: 'Temporary password expired',
								okText: 'I got it',
								showDialog: true,
								keepOriginal: true,
							}
						)
					} else if (graphQLErrors?.[0]?.extensions?.error === 'wrong_username_or_password_error') {
						notifyStore.error(
							`It looks like you entered incorrect credentials. You have ${
								5 - (graphQLErrors?.[0]?.extensions?.failed_attempts || 0)
							} attempts remaining. After 5 failed attempts, your account will be temporarily locked.`,
							{
								title: 'Login failed',
								okText: 'Try again',
								showDialog: true,
								keepOriginal: true,
								showButtonClose: false,
							}
						)
					} else if (graphQLErrors?.[0]?.extensions?.error === 'locked_access_error') {
						notifyStore.error(
							'Your account are now temporarily locked. Please check your email for the unlock instruction.',
							{
								title: 'You have failed to sign in for 5 times',
								okText: 'I got it',
								showDialog: true,
								keepOriginal: true,
							}
						)
					} else {
						notifyStore.error(error?.message)
					}
					resolve()
				}
			})
		})
	}

	@action
	loginSSO = async (variables) => {
		const token = toJS(authStore.authorization)
		const customHeaders = {}
		const getTokenCandidate = window.localStorage.getItem('candidate-token')
		if (token) {
			customHeaders['Auth-Token'] = getTokenCandidate
		}

		customHeaders['company-id'] = process.env.REACT_APP_COMPANY_ID
		customHeaders['OPUS_POLICIES'] = 'IRC'

		try {
			const {
				data: { signInWorker },
			} = await apolloClient.mutate({
				mutation: SIGNIN_WORKER_MUTATION,
				variables: variables,
				context: {
					headers: customHeaders,
				},
			})

			//const { authToken, worker, notificationChannel } = signInWorker
			const { authToken, worker, notificationChannel } = signInWorker
			await authStore.handleAuthAnonymous(false)
			this.changePasswordRequired = worker?.changePasswordRequired
			this.workerID = worker?.id
			if (worker?.changePasswordRequired) {
				await authStore.changeToken(authToken)
			} else {
				await authStore.changeProfile(worker, notificationChannel)
				await authStore.changeToken(authToken)
			}
			await notifyStore.success('Login successfully')
		} catch (error) {
			await notifyStore.error(error.message)
		}
	}

	@action
	toogleChangePasswordRequired = async (value) => {
		this.changePasswordRequired = value
	}

	@action
	setCampaigns = async (value) => {
		this.campaigns = value
	}

	@action
	setHideCheckBoxConfirm = async (value) => {
		this.hideCheckBoxConfirm = value
	}

	@action
	setShowCampaign = async (value) => {
		this.showCampaign = value
	}

	@action
	fetchCampaigns = async () => {
		try {
			const { data } = await apolloClient.query({
				query: GET_CAMPAIGNS_QUERY,
			})
			this.campaigns = data?.campaigns
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchCampaignDetail = async (id) => {
		try {
			const { data } = await apolloClient.query({
				query: GET_CAMPAIGN_WOKER_DETAIL_QUERY,
				variables: {
					id,
				},
			})
			this.campaign = data?.campaign
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchOnboardingOverview = async () => {
		const { data } = await apolloClient.query({
			query: GET_WORKER_ONBOARDING_INFO,
		})
		this.onboardingSteps = data?.workerOnboarding?.onboardingSteps ?? []
	}

	@action
	handleChangePassword = async (values) => {
		const tokenType = authStore.tokenType
		await apolloClient
			.mutate({
				mutation: CHANGE_WORKER_PASSWORD,
				variables: {
					password: values.password,
					oldPassword: this.tempPassword,
					passwordConfirmation: values.passwordConfirmation,
				},
				context: {
					headers: { Authorization: `${tokenType === 'bearer' ? 'Bearer' : ''} ${this.tempToken.accessToken}` },
				},
			})
			.then(async (response) => {
				const authToken = response?.data?.changePassword?.authToken

				await authStore.changeProfile(this.tempWorker, this.tempNotificationChannel)
				await authStore.changeToken(authToken)
				await this.fetchOnboardingOverview()
				await this.fetchCampaigns()
				this.changePasswordRequired = false
				this.tempToken = authToken
				this.tempNotificationChannel = {}
				notifyStore.success('Your password has been updated')
				await this.pickSubmissionPopup(this.tempWorker)
				if (!isEmpty(commonSigninStore.activeCampaign) && isNil(this.activeCampaign?.workerCampaign?.webDismissAt)) {
					this.setShowCampaign(true)
				}
				this.tempPassword = ''
				this.tempWorker = {}
			})
	}

	pickSubmissionPopup = async (worker) => {
		if (
			!worker?.workAuthorizedAt &&
			this.onboardingSteps?.find((item) => item.stepName === 'pre_screen')?.completedPercent === 0
		) {
			await careHomeStore.openRefineMatchesDialog()
		} else {
			if (!worker?.workAuthorizedAt) {
				await guidanceStore.toggleBeReadySubmissionDialog()
			}
		}
	}
}

export const commonSigninStore = new CommonSigninStore()
