import flatten, { unflatten } from 'flat'
import { pickBy, isUndefined, get, join, startCase, toLower, isEmpty } from 'lodash'
import parsePlace from 'parse-google-place'

export const trimData = (values, pickFunc = (value) => !isUndefined(value)) => {
	return unflatten(pickBy(flatten(values), pickFunc))
}
export function capitalizeFirstLetter(string) {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}
}

export const parseAddress = (place) => {
	const data = parsePlace(place)

	const location = place?.geometry?.location?.toJSON()

	return {
		city: data.city,
		country: data?.countryShort,
		countryShort: data?.countryShort,
		latitude: location?.lat,
		longitude: location?.lng,
		street: data?.address?.toString(),
		zipcode: data?.zipCode?.toString(),
		state: data?.stateShort,
		stateShort: data?.stateShort,
		formatted_address: place?.formatted_address,
	}
}

export const parseLocation = (data) => {
	return {
		name: data?.name,
		city: data?.name,
		country: data?.country_code,
		countryShort: data?.country_code,
		latitude: data?.latitude ? parseFloat(data?.latitude) : undefined,
		longitude: data?.longitude ? parseFloat(data?.longitude) : undefined,
		state: data?.state_code,
		stateCode: data?.state_code,
		fullAddress: data?.full_address,
	}
}

export const parseAddressToView = (address, fields = ['city', 'state']) =>
	join(fields.map((field) => get(address, field)).filter(Boolean), ', ')

export const createCursor = (limit, page) => {
	let encode = limit * (page - 1)
	encode = encode.toString()
	return btoa(encode)
}

export const capitalizeEachWork = (string) => {
	if (string) {
		return string
			.split(' ')
			.map((word) => startCase(toLower(word)))
			.join(' ')
	}
	return null
}

export const pickSpecialtyPlaceholder = (disciplineId, filteredSpecialtyOptions) => {
	if (!disciplineId) return '$PLACEHOLDERS.SELECT_YOUR_SPECIALTY'
	if (disciplineId && !isEmpty(filteredSpecialtyOptions)) return '$PLACEHOLDERS.SELECT_SPECIALTY_FIELD'
	else if (disciplineId && isEmpty(filteredSpecialtyOptions)) return '$PLACEHOLDERS.NO_SPECIALTY'
}
