import React from 'react'

export const FEATURES = {
	home: React.lazy(() => import('~/features/care-home')),
	completion_homepage: React.lazy(() => import('~/features/care-activation')),
	timecards: React.lazy(() => import('~/features/care-timecards')),
	signup: React.lazy(() => import('~/features/care-signup')),
	profile: React.lazy(() => import('~/features/care-profile')),
	'popular-cities': React.lazy(() => import('~/features/care-popular-cities')),
	'popular-cities-detail': React.lazy(() => import('~/features/care-popular-cities-detail')),
	'find-job': React.lazy(() => import('~/features/care-find-job')),
	'urgent-needs': React.lazy(() => import('~/features/urgent-needs')),
	'explore-matches': React.lazy(() => import('~/features/explore-matches')),
	'trending-jobs': React.lazy(() => import('~/features/trending-jobs')),
	discover: React.lazy(() => import('~/features/discover')),
	'my-jobs': React.lazy(() => import('~/features/care-my-jobs')),
	credential: React.lazy(() => import('~/features/care-credential')),
	jobs: React.lazy(() => import('~/features/care-jobs')),
	benefits: React.lazy(() => import('~/features/care-benefits')),
	referrer: React.lazy(() => import('~/features/care-referral')),
	'referral-traveller': React.lazy(() => import('~/features/care-referral-traveller')),
	'contact-us': React.lazy(() => import('~/features/care-contact-us')),
	settings: React.lazy(() => import('~/features/care-settings')),
	'job-results': React.lazy(() => import('~/features/care-job-results')),
	'list-jobs': React.lazy(() => import('~/features/care-list-jobs')),
	'last-viewed': React.lazy(() => import('~/features/last-viewed')),
	'last-searches': React.lazy(() => import('~/features/last-searches')),
	bookmark: React.lazy(() => import('~/features/bookmark')),
	'job-alerts': React.lazy(() => import('~/features/job-alerts')),
	'privacy-policy': React.lazy(() => import('~/features/privacy-policy')),
	'update-email-confirmation': React.lazy(() => import('~/features/common-update-email-confirmation')),
	// 'update-email': React.lazy(() => import('~/features/common-update-email')),
	'news-feed': React.lazy(() => import('~/features/new-feed')),
	notification: React.lazy(() => import('~/features/care-notification')),
	credentials: React.lazy(() => import('~/features/credentials')),
	resources: React.lazy(() => import('~/features/resources')),
	'invite-friend': React.lazy(() => import('~/features/care-invite-friend')),

	// COMMON FEATURES
	'common-maintenance': React.lazy(() => import('~/features/common-maintenance')),
	signin: React.lazy(() => import('~/features/common-signin')),
	messages: React.lazy(() => import('~/features/care-messages')),
	authorization: React.lazy(() => import('~/features/common-authorization')),
	errors: React.lazy(() => import('~/features/common-errors')),
	reset: React.lazy(() => import('~/features/common-reset')),
	'change-password': React.lazy(() => import('~/features/common-change-password')),
	'link-options': React.lazy(() => import('~/features/common-link-options')),
	shares: React.lazy(() => import('~/features/shares')),
}
