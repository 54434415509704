export const EDUCATION_PROFILE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-management-educations',
	SCREEN_EDIT_TRACKING: 'edit-education',
	SCREEN_ADD_TRACKING: 'add-education',
	SCREEN_REMOVE_TRACKING: 'confirm-remove-education',
	SCREEN_DENY_TRACKING: 'DENY-REMOVE-EDUCATION',
	SUBMIT_EDIT_SUCCESS: 'EDIT-EDUCATIONS_SUBMIT_SUCCESS',
	SUBMIT_EDIT_FAILED: 'EDIT-EDUCATIONS_SUBMIT_FAILED',
	SUBMIT_ADD_SUCCESS: 'ADD-EDUCATIONS_SUBMIT_SUCCESS',
	SUBMIT_ADD_FAILED: 'ADD-EDUCATIONS_SUBMIT_FAILED',
	SUBMIT_REMOVE_SUCCESS: 'REMOVE-EDUCATIONS_SUBMIT_SUCCESS',
	SUBMIT_REMOVE_FAILED: 'REMOVE-EDUCATIONS_SUBMIT_FAILED',
	CLICK_ADD_PROFILE_BTN: 'add_btn',
	CLICK_EDIT_PROFILE_BTN: 'edit_btn',
	CLICK_REMOVE_EDUCATION_BTN: 'remove_btn',
	CLICK_SAVE_EDIT_EDUCATION_BTN: 'save_btn_edit',
	CLICK_CLOSE_EDIT_EDUCATION_BTN: 'close-screen_btn_edit',
	CLICK_SAVE_ADD_EDUCATION_BTN: 'save_btn_add-education',
	CLICK_CLOSE_ADD_EDUCATION_BTN: 'close-screen_btn_add-education',
	CLICK_CANCEL_BTN_CONFIRM_REMOVE_EDUCATION: 'cancel_btn_confirm',
	CLICK_CONFIRM_BTN_CONFIRM_REMOVE_EDUCATION: 'confirm_btn_confirm',
	CLICK_OK_BTN_DENY_REMOVE_EDUCATION: 'ok_btn_deny-remove-education',
}

export const WORK_PROFILE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile',
	SCREEN_EDIT_TRACKING: 'edit-work-experience',
	SCREEN_ADD_TRACKING: 'add-work-experience',
	SCREEN_REMOVE_TRACKING: 'confirm-remove-work-experience',
	SCREEN_DENY_TRACKING: 'deny-remove-work-experience',
	SUBMIT_EDIT_SUCCESS: 'EDIT-WORK-EXPERIENCES_SUBMIT_SUCCESS',
	SUBMIT_EDIT_FAILED: 'EDIT-WORK-EXPERIENCES_SUBMIT_FAILED',
	SUBMIT_ADD_SUCCESS: 'ADD-WORK-EXPERIENCES_SUBMIT_SUCCESS',
	SUBMIT_ADD_FAILED: 'ADD-WORK-EXPERIENCES_SUBMIT_FAILED',
	SUBMIT_REMOVE_SUCCESS: 'REMOVE-WORK-EXPERIENCES_SUBMIT_SUCCESS',
	SUBMIT_REMOVE_FAILED: 'REMOVE-WORK-EXPERIENCES_SUBMIT_FAILED',
	CLICK_ADD_PROFILE_BTN: 'add_btn_profile',
	CLICK_EDIT_PROFILE_BTN: 'edit_btn_profile',
	CLICK_REMOVE_BTN: 'remove_btn_profile',
	CLICK_SAVE_EDIT_BTN: 'save_btn_edit',
	CLICK_CLOSE_EDIT_BTN: 'close-screen_btn_edit',
	CLICK_SAVE_ADD_BTN: 'save_btn_add',
	CLICK_CLOSE_ADD_BTN: 'close-screen_btn_add',
	CLICK_CANCEL_BTN_CONFIRM_REMOVE: 'cancel_btn_confirm',
	CLICK_CONFIRM_BTN_CONFIRM_REMOVE: 'confirm_btn_remove',
	CLICK_OK_BTN_DENY_REMOVE: 'ok_btn_deny',
}

export const REFERENCE_PROFILE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-management-references',
	SCREEN_EDIT_TRACKING: 'edit-reference',
	SCREEN_ADD_TRACKING: 'add-reference',
	SCREEN_REMOVE_TRACKING: 'confirm-remove-reference',
	SCREEN_DENY_TRACKING: 'deny-remove-reference',
	SUBMIT_EDIT_SUCCESS: 'EDIT-REFERENCES_SUBMIT_SUCCESS',
	SUBMIT_EDIT_FAILED: 'EDIT-REFERENCES_SUBMIT_FAILED',
	SUBMIT_ADD_SUCCESS: 'ADD-REFERENCES_SUBMIT_SUCCESS',
	SUBMIT_ADD_FAILED: 'ADD-REFERENCES_SUBMIT_FAILED',
	SUBMIT_REMOVE_SUCCESS: 'REMOVE-REFERENCES_SUBMIT_SUCCESS',
	SUBMIT_REMOVE_FAILED: 'REMOVE-REFERENCES_SUBMIT_FAILED',
	CLICK_ADD_PROFILE_BTN: 'add_btn',
	CLICK_EDIT_PROFILE_BTN: 'edit-reference_btn',
	CLICK_REMOVE_BTN: 'remove-reference_btn',
	CLICK_SAVE_EDIT_BTN: 'save_btn_edit-reference',
	CLICK_CLOSE_EDIT_BTN: 'close-screen_btn_edit',
	CLICK_SAVE_ADD_BTN: 'save_btn_add',
	CLICK_CLOSE_ADD_BTN: 'close-screen_btn_add',
	CLICK_CANCEL_BTN_CONFIRM_REMOVE: 'cancel_btn',
	CLICK_CONFIRM_BTN_CONFIRM_REMOVE: 'confirm_btn',
	CLICK_OK_BTN_DENY_REMOVE: 'ok_btn_deny',
}

export const CHECK_LIST_PROFILE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-management-skill-check-lists',
	SCREEN_POPUP_TRACKING: 'confirm-open-una-linke',
	CLICK_OPEN_UNA_LINK_BTN: 'open-una-link_btn_profile-management-skill-check-lists',
}

export const TERM_PROFILE_TRACKING_EVENT = {
	SCREEN_TRACKING: 'profile-management-term-signed',
}
