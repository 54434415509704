import { action, observable, store } from '~/common/mobx.decorator'

@store()
class BreadcumbsStore {
	@observable breadcrumItems = []
	@action
	setBreadcrumb = (items) => {
		this.breadcrumItems = items
	}
	@action
	removeBreadcrumb = () => {
		this.breadcrumItems = []
	}
}

export const breadcrumbsStore = new BreadcumbsStore()
