import gql from 'graphql-tag'

export const GET_COMPANY_INFO_QUERY = gql`
	query getCompanyInfo {
		appInit {
			tenant {
				name
			}

			tenantContacts {
				email
				phone
				contactType
			}

			tenantConfig {
				termUrl
				contactEmail
				twitterUrl
				facebookUrl
				linkedInUrl
				instagramUrl
				tiktokUrl
				youtubeUrl
			}
		}
	}
`

export const GET_SPECIALTY_MASTER_QUERY = gql`
	query SpecialtiesMaster {
		specialtiesMaster {
			id
			slug
		}
	}
`

export const GET_CURRENT_WORKER_INFO = gql`
	query {
		appInit {
			worker {
				id
				email
				firstName
				lastName
				maidenName
			}
		}
	}
`
