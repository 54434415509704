import React from 'react'
import { SvgIcon, Typography as MuiTypography, useTheme } from '@material-ui/core'
import { typographyStyle } from './typography.style'

export const Typography = ({ icon: Icon, children, ...props }) => {
	const { typography } = useTheme()
	const baseStyle = typography[props.variant] || typography.body1
	const style = { ...baseStyle, ...props.style }

	return (
		<MuiTypography {...props} style={style} css={typographyStyle}>
			{Icon && (
				<SvgIcon>
					<Icon />
				</SvgIcon>
			)}
			{children}
		</MuiTypography>
	)
}
