export const CERTIFICATION_TRACKING = {
	LOG_SCREEN: 'licenses-certifications',
	MINIMUM_SCREEN: 'minimum-1-license',
	REMOVE_SCREEN: 'remove-license',
	ADD_SCREEN: 'add-license',
	EDIT_SCREEN: 'edit-license',
	MINIMUM_CERT_SCREEN: 'minimum-1-certification',
	REMOVE_CERT_SCREEN: 'remove-certification',
	ADD_CERT_SCREEN: 'add-certification',
	EDIT_CERT_SCREEN: 'edit-certification',

	MINIMUM_EDU_SCREEN: 'minimum-1-certification',
	ADD_EDU_SCREEN: 'add-certification',
	EDIT_EDU_SCREEN: 'edit-certification',
	REMOVE_EDU_SCREEN: 'remove-certification',

	EDIT_BTN: 'edit-license_btn',
	REMOVE_BTN: 'remove-license_btn',
	ADD_BTN: 'add-license_btn',

	EDIT_EDU_BTN: 'edit-certification_btn',
	REMOVE_EDU_BTN: 'remove-certification_btn',
	ADD_EDU_BTN: 'add-certification_btn',

	CANCEL_BTN: 'cancel_btn',
	CONFIRM_BTN: 'confirm_btn',
	I_GOT_IT_BTN: 'i-got-it_btn',
	SAVE_BTN: 'save_btn',
	CLOSE_BTN: 'close_btn',
	ADD_LICENSE_BTN: 'add-license_btn',
	UPLOAD_LICENSE_BTN: 'upload-license_btn',
	DELETE_LICENSE_BTN: 'delete-license_btn',

	ADD_CERT_BTN: 'add-cert_btn',
	UPLOAD_CERT_BTN: 'upload-cert_btn',
	DELETE_CERT_BTN: 'delete-cert_btn',
}
