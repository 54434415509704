import { gql } from '@apollo/client'

export const MARK_NOTIFICATION_STATUS_MUTATION = gql`
	mutation MarkNotificationsStatus($notificationIds: [String!]!, $status: NotificationStatusEnum) {
		markNotificationsStatus(notificationIds: $notificationIds, status: $status) {
			success
		}
	}
`
export const MARK_ALL_NOTIFICATION_STATUS_MUTATION = gql`
	mutation MarkAllNotificationsStatus($status: NotificationStatusEnum) {
		markAllNotificationsStatus(status: $status) {
			success
		}
	}
`

export const DELETE_NOTIFICATION_MUTATION = gql`
	mutation DeleteNotification($notificationIds: [String!]!) {
		destroyNotifications(notificationIds: $notificationIds) {
			success
		}
	}
`

export const DELETE_ALL_READ_NOTIFICATION_MUTATION = gql`
	mutation DeleteAllReadNotification {
		destroyNotifications {
			success
		}
	}
`
export const GET_NOTIFICATIONS = gql`
	query getNotifications(
		$after: String
		$first: Int
		$before: String
		$last: Int
		$events: [String!]
		$exceptEvents: [String!]
	) {
		notifications(
			after: $after
			first: $first
			before: $before
			last: $last
			events: $events
			exceptEvents: $exceptEvents
		) {
			nodes {
				createdAt
				event
				heading
				id
				message
				objectId
				objectType
				additionalData
				status
				updatedAt
				url
			}
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			totalCount
			totalRead
			totalUnread
		}
	}
`
