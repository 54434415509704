import { gql } from '@apollo/client'
import {
	JOB_BASED_FRAGMENT,
	WORKER_ASSIGNMENT_FRAGMENT,
	WORKER_FRAGMENT,
	JOB_SEARCH_FRAGMENT,
	JOB_DETAIL_FRAGMENT,
	JOB_ASSIGMENT_FRAGMENT,
	JOB_FRAGMENT,
} from '../apollo.fragment'

export const GET_GENERAL_RANKED_JOBS_QUERY = gql`
	query popularCities {
		popularCities {
			city
			state
			totalJobs
			highWeeklyRate
			lowWeeklyRate
			locationImage {
				id
				webImage {
					fileUrl
				}
				thumbnailImage {
					fileUrl
				}
			}
		}
	}
`

export const GET_ASSIGMENT_JOB_QUERY = gql`
	query getActiveWorkerAssignments {
		workerAssignments {
			completed {
				...workerAssignmentFragment
				job {
					...jobDetailFragment
				}
			}
			current {
				...workerAssignmentFragment
				job {
					...jobDetailFragment
				}
			}
			upcoming {
				...workerAssignmentFragment
				job {
					...jobDetailFragment
				}
			}
		}
	}
	${JOB_DETAIL_FRAGMENT}
	${WORKER_ASSIGNMENT_FRAGMENT}
`

export const GET_JOB_APPLICATIONS_COUNT_QUERY = gql`
	query getJobApplicantsCount($withoutAssignments: Boolean) {
		jobApplicantsCount(withoutAssignments: $withoutAssignments) {
			byJobStatus
			byStatus
		}
	}
`

export const GET_POPULAR_CITIES_QUERY = gql`
	query popularCities {
		popularCities {
			city
			state
			totalJobs
			highWeeklyRate
			lowWeeklyRate
			locationImage {
				id
				webImage {
					fileUrl
				}
				thumbnailImage {
					fileUrl
				}
			}
		}
	}
`

export const GET_DETAIL_RANKED_JOBS_QUERY = gql`
	query PopularCityJobs($state: String!, $city: String!, $offset: Int, $limit: Int, $filter: JobFilter) {
		popularCityJobs(state: $state, city: $city, offset: $offset, limit: $limit, filter: $filter) {
			jobs {
				...jobBasedFragment
			}
			totalCount
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_POPULAR_CITY_JOBS = gql`
	query popularCityJobsQuery($state: String!, $city: String!, $after: String, $first: Int, $sortBy: JobManagementSort) {
		popularCityJobs(state: $state, city: $city, after: $after, first: $first, sortBy: $sortBy) {
			nodes {
				...jobBasedFragment
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			totalCount
		}
	}
	${JOB_BASED_FRAGMENT}
`

export const GET_RECOMMENDED_JOBS_QUERY = gql`
	query getSuggestionJobsQuery(
		$sortBy: JobManagementSort
		$after: String
		$before: String
		$first: Int
		$last: Int
		$filter: JobFilter
	) {
		suggestionJobs(sortBy: $sortBy, after: $after, before: $before, first: $first, last: $last, filter: $filter) {
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			nodes {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_SIMILAR_JOBS_QUERY = gql`
	query getSimilarSearchJobsQuery($filter: JobFilter, $sortBy: JobManagementSort, $limit: Int, $offset: Int) {
		similarSearchJobs(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
			totalCount
			jobs {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_HOT_JOBS_QUERY = gql`
	query getHotJobsQuery($filter: JobFilter, $before: String, $after: String, $sortBy: JobManagementSort, $first: Int) {
		searchJobs(filter: $filter, after: $after, before: $before, sortBy: $sortBy, first: $first) {
			totalCount
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
			}
			nodes {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_ACTIVE_JOBS_QUERY = gql`
	query getActiveJobsQuery {
		activeJobs {
			completedJobs {
				...jobBasedFragment
			}
			currentJobs {
				...jobBasedFragment
			}
			nextJobs {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_JOBS_QUERY = gql`
	query getSearchJobsQuery($filter: JobFilter, $before: String, $first: Int, $last: Int) {
		searchJobs(filter: $filter, first: $first, before: $before, last: $last) {
			nodes {
				...jobSearchFragment
			}
			edges {
				cursor
				node {
					...jobSearchFragment
				}
			}

			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			totalCount
		}
	}

	${JOB_SEARCH_FRAGMENT}
`

export const GET_ACTIVE_WORKER_ASSIGNMENT_QUERY = gql`
	query getActiveWorkerAssignment {
		activeListWorkerAssignments {
			completed {
				...workerAssignmentFragment
			}
			current {
				...workerAssignmentFragment
			}
			next {
				...workerAssignmentFragment
			}
		}
	}

	${WORKER_ASSIGNMENT_FRAGMENT}
`
export const GET_FAVORITE_JOB_DISCOVERS_QUERY = gql`
	query getListFavoriteJobDiscoversQuery {
		discovers {
			favoritedJobs
		}
	}
`

export const GET_APPLIED_JOBS_QUERY = gql`
	query getAppliedJobsQuery($filter: JobApplicantStatusEnum, $offset: Int, $limit: Int) {
		appliedJobs(filter: $filter, offset: $offset, limit: $limit) {
			id
			status
			createdAt
			updatedAt
			job {
				...jobBasedFragment
			}
			worker {
				...workerFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
	${WORKER_FRAGMENT}
`

export const GET_MY_JOBS_QUERY = gql`
	query getMyJobApplicants(
		$filter: JobApplicantFilter
		$after: String
		$before: String
		$first: Int
		$last: Int
		$withoutAssignments: Boolean
	) {
		jobApplicants(
			filter: $filter
			after: $after
			before: $before
			first: $first
			last: $last
			withoutAssignments: $withoutAssignments
		) {
			nodes {
				...jobAssigmentFragment
			}
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
		}
	}
	${JOB_ASSIGMENT_FRAGMENT}
`

export const GET_FAVORITED_JOBS_QUERY = gql`
	query getfavoritedJobsQuery($after: String, $before: String, $first: Int, $last: Int) {
		likedJobs(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_JOB_DISCOVERS_QUERY = gql`
	query JobDiscovers {
		discovers {
			available
			favoritedJobs
			lastSearch {
				count
				type
				savedFilterId
				filterConditions
			}
			recommendation
			lastViewedJobs
		}
	}
`

export const GET_DISCOVER_LAST_SEARCH = gql`
	query getDiscoverLastSearch {
		discovers {
			lastSearch {
				count
				type
				savedFilterId
				filterConditions
			}
		}
	}
`

export const GET_ALLOW_DISMISS_BULLETIN_QUERY = gql`
	query AllowDismissedBulletins {
		allowDismissedBulletins {
			id
			isDismissed
			name
		}
	}
`

export const DISMISS_BULLETIN_MUTATION = gql`
	mutation dismissBulletinMutation($bulletinId: String!) {
		dismissBulletin(bulletinId: $bulletinId) {
			bulletinId
			success
		}
	}
`

export const REPLAY_BULLETIN_MUTATION = gql`
	mutation ReplayDismissedBulletin($bulletinIds: [String!]!) {
		replayDismissedBulletin(bulletinIds: $bulletinIds) {
			success
		}
	}
`

export const GET_TRENDING_JOBS_QUERY = gql`
	query getTrendingJobsQuery($filter: JobFilter, $sortBy: JobManagementSort, $limit: Int, $offset: Int) {
		trendingJobs(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
			totalCount
			jobs {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`

export const GET_LAST_JOBS_DISCOVER_COUNT = gql`
	query getLastJobsDiscoverCount {
		discovers {
			lastViewedJobs
		}
	}
`

export const GET_LAST_JOBS_QUERY = gql`
	query getLastViewedJobs($filter: JobFilter, $after: String, $first: Int, $last: Int, $sortBy: JobManagementSort) {
		lastViewedJobs(filter: $filter, after: $after, last: $last, first: $first, sortBy: $sortBy) {
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_LIST_JOBS_SEO_QUERY = gql`
	query getListJobsSeoQuery($after: String, $before: String, $first: Int, $last: Int) {
		listJobs(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobBasedFragment
			}
		}
	}

	${JOB_BASED_FRAGMENT}
`
