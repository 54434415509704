import { gql } from '@apollo/client'

export const PROCESS_INTERVIEW_MUTATION = gql`
	mutation workerProcessInterviewMutation(
		$workerProcessInterviewId: String!
		$event: String!
		$note: String
		$interviewStartTime: ISO8601DateTime
		$interviewEndTime: ISO8601DateTime
		$timezone: String
	) {
		workerProcessInterview(
			id: $workerProcessInterviewId
			event: $event
			note: $note
			interviewStartTime: $interviewStartTime
			timezone: $timezone
			interviewEndTime: $interviewEndTime
		) {
			id
			status
			statusName
		}
	}
`
export const VERIFY_EXISTED_EMAIL = gql`
	mutation verifyExistedEmailMutation($email: String!, $recaptchaToken: String!) {
		verifyExistedEmail(email: $email, recaptchaToken: $recaptchaToken) {
			existed
		}
	}
`

export const PROCESS_RESCHEDULE_INTERVIEW_MUTATION = gql`
	mutation WorkerRescheduleInterviewMution(
		$workerRescheduleInterviewId: String!
		$interviewStartTime: ISO8601DateTime
		$interviewEndTime: ISO8601DateTime
		$timezone: String
	) {
		workerRescheduleInterview(
			id: $workerRescheduleInterviewId
			interviewStartTime: $interviewStartTime
			interviewEndTime: $interviewEndTime
			timezone: $timezone
		) {
			id
			status
			statusName
		}
	}
`
