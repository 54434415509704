import { action, computed, observable, store } from '~/common/mobx.decorator'
import { apolloClient, GET_LAST_JOBS_QUERY } from '~/common/apollo'
import { LIMIT_PAGE, SORT_TYPE } from '~/common/constants'
import { createCursor } from '~/common/helpers'
import { logDefaultActionEvent } from '~/common/tracking/event-client.tracking'
import { EVENT_DEFAULT_ID, TYPE_JOB } from '~/common/tracking/event-click.constant'

@store()
class LastViewedStore {
	@observable lastViewedJobs = []
	@observable pageInfo = {}
	@observable totalCount = 0
	@observable order = SORT_TYPE.desc
	@observable field = 'SCORE'
	@observable page

	@computed
	get lastViewedJobsItems() {
		return this.lastViewedJobs
	}

	@computed
	get hasNextPage() {
		return this.pageInfo?.hasNextPage
	}

	@computed
	get hasPreviousPage() {
		return this.pageInfo?.hasPreviousPage
	}

	@computed
	get pageSize() {
		return LIMIT_PAGE
	}

	@action
	resetCountLastView = () => {
		this.totalCount = 0
	}

	@action
	fetchLastViewedJobs = async () => {
		// if (!authStore.id) {
		// 	const lastViewedJobsStore = localStorage.getItem('lastViewJobs')
		// 	const lastViewedJobsMapping = JSON.parse(lastViewedJobsStore)?.map((item) => item?.job)
		// 	this.lastViewedJobs = lastViewedJobsMapping ? lastViewedJobsMapping : []
		// 	this.totalCount = lastViewedJobsMapping?.length || 0
		// } else {
		const response = await apolloClient.query({
			query: GET_LAST_JOBS_QUERY,
			variables: {
				sortBy: {
					field: this.field,
					order: this.order,
				},
				first: LIMIT_PAGE,
			},
		})

		this.lastViewedJobs = response?.data?.lastViewedJobs.nodes
		this.totalCount = response?.data?.lastViewedJobs.totalCount
		this.pageInfo = response?.data?.lastViewedJobs.pageInfo

		logDefaultActionEvent(EVENT_DEFAULT_ID.loadInitialLanding, {
			jobs: this.lastViewedJobs?.map((x, index) => {
				return {
					id: x?.id,
					min_wage: x?.payAmountMin,
					max_wage: x?.payAmountMax,
					order: index,
					percent_matched: x?.matchingPercentage,
				}
			}),
			page_number: this.totalCount === 0 ? 1 : Math.ceil(this.lastViewedJobs?.length / 20) + 1,
			limit: 20,
			list_type: TYPE_JOB.lastViewJobs,
		})
		// }
	}

	@action
	onSort = async (field) => {
		const response = await apolloClient.query({
			query: GET_LAST_JOBS_QUERY,
			variables: {
				sortBy: {
					field,
					order: this.order,
				},
				first: LIMIT_PAGE,
				after: createCursor(LIMIT_PAGE, this.page),
			},
		})
		this.field = field
		this.lastViewedJobs = response?.data?.lastViewedJobs.nodes
		this.totalCount = response?.data?.lastViewedJobs.totalCount
		this.pageInfo = response?.data?.lastViewedJobs.pageInfo
	}

	@action
	onLoadMore = async (page) => {
		const response = await apolloClient.query({
			query: GET_LAST_JOBS_QUERY,
			variables: {
				sortBy: {
					field: this.field,
					order: this.order,
				},
				first: LIMIT_PAGE,
				after: createCursor(LIMIT_PAGE, page),
			},
		})
		this.page = page
		this.lastViewedJobs = response?.data?.lastViewedJobs.nodes
		this.pageInfo = response?.data?.lastViewedJobs.pageInfo

		logDefaultActionEvent(EVENT_DEFAULT_ID.loadMoreLanding, {
			jobs: this.lastViewedJobs?.map((x, index) => {
				return {
					id: x.id,
					avg_weekly: x?.weeklyPayAmount,
					order: index,
					percent_matched: x?.matchingPercentage,
				}
			}),
			page_number: this.totalCount === 0 ? 1 : Math.ceil(this.lastViewedJobs?.length / 20) + 1,
			limit: 20,
			offset: this.lastViewedJobs?.length || 0,
			list_type: TYPE_JOB.lastViewJobs,
		})
	}
}

export const lastViewedStore = new LastViewedStore()
