export const PHONE_FORMAT = '(###) ###-####'
export const SHORT_FREQUENCY_OPTIONS = {
	daily: 'Dly',
	hourly: 'Hr',
	monthly: 'Mth',
	yearly: 'Yr',
	weekly: 'Wk',
}

export const BADGE_TAB = {
	earned: 'earned',
	all: 'all',
}

export const CREDENTIALS_COMPLETE_TYPE = {
	external: 'external',
	in_app: 'in_app',
}

export const CREDENTIALS_STATUS = {
	pending: 'pending',
	in_verification: 'in_verification',
	verified: 'verified',
	expired: 'expired',
}

export const CREDENTIALS_ATS_SOURCE = {
	bullhorn: 'bullhorn',
	symplr: 'symplr',
}

export const SOCIAL_PROFIDER_MAPPING = {
	google: 'Google',
	facebook: 'Facebook',
	apple: 'Apple ID',
	linkedin: 'LinkedIn',
}

export const SUBMISSION_READY_ITEMS_COUNT = 5
export const CREDENTIALS_OTHERS = 'Others'
export const SKILL_CHECKLIST_STATUS = {
	in_complete: 'in_complete',
	in_progress: 'in_progress',
	completed: 'completed',
}

export const NOTIFICATION_EVENT_CODE = {
	credential_incompleted: 'credential_incompleted',
	credential_new_request: 'credential_new_request',
	credential_expired_soon: 'credential_incompleted',
	timecard_submission: 'timecard_submission',
	campaign: 'campaign',
}
