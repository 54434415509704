import { isEmpty } from 'lodash'
import { EVENTS, NOTIFICATION_EVENT_CODE, PATHS } from '~/common/constants'
import { action, computed, event, observable, store } from '~/common/mobx.decorator'
import Bowser from 'bowser'
import { authStore, routerStore } from '~/stores'
import { commonSigninStore } from '~/features/common-signin/common-signin.store'

@store()
class NotifyStore {
	@observable messages = []
	@observable doneMessages = {}
	@observable isSubscribed = false
	@observable dialogs = []
	@observable doneDialogs = {}
	@observable isOpenNotification = false
	@observable isErrorInitOneSignal = false
	@computed
	get waitMessages() {
		return this.messages.filter((message) => !this.doneMessages[message.key])
	}

	@computed
	get waitDialogs() {
		return this.dialogs.filter((dialog) => !this.doneDialogs[dialog.key])
	}

	@computed
	get signal() {
		return window.OneSignal || []
	}

	@action
	open = async (message, options) => {
		const data = {
			key: `${Date.now()}_${Math.random()}`,
			message,
			options,
		}

		if (options.showDialog) {
			this.dialogs.push(data)

			return data.key
		}
		if (options?.onlyWebPush) {
			return
		}

		this.messages.push(data)
		return data.key
	}

	@action
	success = async (message, options) => {
		return this.open(message, { ...options, variant: 'success', customization: true })
	}

	@action
	error = async (message, options) => {
		return this.open(message, { ...options, variant: 'error' })
	}

	@action
	info = async (message, options) => {
		return this.open(message, { ...options, variant: 'info' })
	}

	@action
	warning = async (message, options) => {
		return this.open(message, { ...options, variant: 'warning' })
	}

	@action
	closeMessage = async (key) => {
		this.doneMessages[key] = true
	}

	@action
	closeDialog = async (key) => {
		this.doneDialogs[key] = true
	}

	@event(EVENTS.notifyStore.fireError)
	handleError({ payload }) {
		this.error(payload?.message)
	}

	@action
	initOneSignal = async () => {
		if (!isEmpty(process.env.REACT_APP_COMPANY_ID)) {
			await this.signal
				.init({
					appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
					autoRegister: false,
					autoResubscribe: false,
					notifyButton: {
						enable: true,
						prenotify: true,
					},
					allowLocalhostAsSecureOrigin: true,
				})
				.then(() => {
					this.isErrorInitOneSignal = false
					this.checkSubscriptionStatus()
				})
				.catch((error) => {
					console.error(error)
					this.isErrorInitOneSignal = true
				})

			if (this.isErrorInitOneSignal) {
				return
			}

			await this.signal.addListenerForNotificationOpened(async (event) => {
				console.log('Here notification opened', event)

				const eventCode = event?.data?.event
				if (
					[
						NOTIFICATION_EVENT_CODE.credential_incompleted,
						NOTIFICATION_EVENT_CODE.credential_new_request,
						NOTIFICATION_EVENT_CODE.credential_expired_soon,
					].includes(eventCode)
				) {
					void routerStore.goPage(PATHS.care.credentials)
				} else if ([NOTIFICATION_EVENT_CODE.timecard_submission].includes(eventCode)) {
					void routerStore.goPage(PATHS.care.timecards)
				} else if (eventCode === 'new_feed') {
					void routerStore.goPage(PATHS.care.news_feed)
				}
				if (eventCode === NOTIFICATION_EVENT_CODE.campaign && commonSigninStore.showCampaign === false) {
					await commonSigninStore.fetchCampaignDetail(event?.data?.objectId)
					await commonSigninStore.setHideCheckBoxConfirm(true)
					await commonSigninStore.setShowCampaign(true)
				}
			})
		}
	}

	@action
	checkSubscriptionStatus = async () => {
		if (this.isErrorInitOneSignal) {
			return
		}

		this.signal.push(async () => {
			const state = await this.signal.getNotificationPermission()
			this.isSubscribed = state === 'granted'
			console.log('Notification permission state:', state)
		})
	}

	@action
	sendInfo = async () => {
		const { notificationChannel, workerEmail, id } = authStore

		if (this.isErrorInitOneSignal) {
			return
		}

		this.signal.push(async () => {
			await this.signal.setExternalUserId(notificationChannel)

			if (!isEmpty(process.env.REACT_APP_ONESIGNAL_APP_ID)) {
				const browser = Bowser.getParser(window.navigator.userAgent)

				await this.checkSubscriptionStatus()

				if (this.isSubscribed) {
					await this.signal.setSubscription(true)
					await this.signal.registerForPushNotifications()
				} else {
					console.log('User is not subscribed. Prompting for subscription.')
					const result = await this.signal.showNativePrompt()
					if (result) {
						this.isSubscribed = true
						console.log('User subscribed successfully')
					} else {
						console.log('User declined to subscribe')
					}
				}

				await this.signal
					.sendTags({
						browser: browser.getBrowser(),
						workerId: id,
						workerEmail: workerEmail,
					})
					.then((tagsSent) => {
						console.log('Tags sent: ', tagsSent)
						console.log('NotificationChannel: ', notificationChannel)
					})
					.catch((e) => {
						console.warn('Error sending tags: ', e)
					})
			}
		})
	}

	@action
	unInitOneSignal = async () => {
		this.signal.push(async () => {
			await this.signal.removeExternalUserId()
			try {
				await this.signal.getTags(async (tags) => {
					const tagKeys = Object.keys(tags)
					if (tagKeys.length > 0) {
						await this.signal.deleteTags(tagKeys)
						console.log('All tags deleted successfully')
					}
				})
			} catch (e) {
				console.warn('Error deleting tags: ', e)
			}
			// Don't unsubscribe, just clear user-specific data
			this.isSubscribed = false
		})
	}
}

export const notifyStore = new NotifyStore()
