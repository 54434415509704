import gql from 'graphql-tag'
import { JOB_BASED_FRAGMENT, JOB_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const CREATE_SAVE_MUTATION = gql`
	mutation CreateSavedFilterMutation(
		$name: String
		$alertEmail: String
		$alertFirstName: String
		$alertLastName: String
		$frequency: String
		$enableAlert: Boolean
		$alertChannels: JSON
		$pauseAlert: Boolean
		$pauseAlertPeriod: String
		$pauseAlertUntil: ISO8601Date
		$filterCondition: JobFilter
		$isDefault: Boolean
	) {
		createSavedFilter(
			name: $name
			alertEmail: $alertEmail
			alertFirstName: $alertFirstName
			alertLastName: $alertLastName
			frequency: $frequency
			enableAlert: $enableAlert
			alertChannels: $alertChannels
			pauseAlert: $pauseAlert
			pauseAlertPeriod: $pauseAlertPeriod
			pauseAlertUntil: $pauseAlertUntil
			filterCondition: $filterCondition
			isDefault: $isDefault
		) {
			alertChannels
			enableAlert
			externalId
			filterCondition {
				conditions
				externalId
				id
			}
			isDefault
			frequency
			id
			lastAlertedAt
			name
			pauseAlert
			pauseAlertPeriod
			pauseAlertUntil
			startPauseAlertDate
		}
	}
`
export const UPDATE_SAVE_MUTATION = gql`
	mutation UpdateSavedFilter(
		$name: String
		$alertEmail: String
		$alertFirstName: String
		$alertLastName: String
		$frequency: String
		$enableAlert: Boolean
		$alertChannels: JSON
		$pauseAlert: Boolean
		$pauseAlertPeriod: String
		$pauseAlertUntil: ISO8601Date
		$filterCondition: JobFilter
		$id: String
		$isDefault: Boolean
	) {
		updateSavedFilter(
			name: $name
			alertEmail: $alertEmail
			alertFirstName: $alertFirstName
			alertLastName: $alertLastName
			frequency: $frequency
			enableAlert: $enableAlert
			alertChannels: $alertChannels
			pauseAlert: $pauseAlert
			pauseAlertPeriod: $pauseAlertPeriod
			pauseAlertUntil: $pauseAlertUntil
			filterCondition: $filterCondition
			isDefault: $isDefault
			id: $id
		) {
			alertChannels
			enableAlert
			externalId
			filterCondition {
				conditions
				externalId
				id
			}
			isDefault
			frequency
			id
			lastAlertedAt
			name
			pauseAlert
			pauseAlertPeriod
			pauseAlertUntil
			startPauseAlertDate
		}
	}
`

export const UPDATE_IS_DEFAULT_SAVE_SEARCH_MUTATION = gql`
	mutation UpdateIsDefaultSaveSearch($isDefault: Boolean, $id: String!) {
		updateIsDefaultSavedFilter(isDefault: $isDefault, id: $id) {
			isDefault
			id
		}
	}
`

export const DELETE_SAVE_MUTATION = gql`
	mutation DeleteSavedFilter($id: String) {
		destroySavedFilter(id: $id) {
			id
			success
		}
	}
`

export const SYNC_ANONYMOUS_WORKER_DATA = gql`
	mutation SyncAnonymousWorkerData(
		$savedFiltersAttributes: [SavedFilterArgument!]
		$workerViewedJobsAttributes: [WorkerViewedJobArgument!]
		$bookmarkJobsAttributes: [BookmarkJobArgument!]
	) {
		syncAnonymousWorkerData(
			bookmarkJobsAttributes: $bookmarkJobsAttributes
			savedFiltersAttributes: $savedFiltersAttributes
			workerViewedJobsAttributes: $workerViewedJobsAttributes
		) {
			success
		}
	}
`
export const GET_DISCOVER_JOB_BOOKMARK = gql`
	query getDiscoverJobBookMark {
		discovers {
			bookmarkedJobs
		}
	}
`

export const GET_LIST_BOOK_MARK = gql`
	query getListFavoriteJobs($after: String, $before: String, $last: Int, $first: Int) {
		favoritedJobs(after: $after, before: $before, last: $last, first: $first) {
			nodes {
				...jobBasedFragment
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			totalCount
		}
	}
	${JOB_BASED_FRAGMENT}
`

export const BOOKMARK_A_JOB_MUTATION = gql`
	mutation BookmarkAJob($jobId: String!, $action: BookmarkActionEnum!) {
		bookmarkAJob(jobId: $jobId, action: $action) {
			...jobFragment
		}
	}
	${JOB_FRAGMENT}
`

export const CLEAR_ALL_DEFAULT_FILTER_MUTATION = gql`
	mutation clearAllDefaultSavedFiltersMutation {
		clearAllDefaultSavedFilters {
			id
			isDefault
		}
	}
`
