import { Box, Dialog, Grid, SvgIcon, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { notifyDialogStyle } from './notify.style'
import { appStore } from '~/stores'
import { CloseIconDialog } from '~/components/icons'
import { Button } from '~/components/button'
import { isEmpty } from 'lodash'
import { logTrackingScreenEvent } from '~/common/tracking/event-client.tracking'

export const NotifyDialog = ({
	title,
	hideTitle,
	okText,
	okIcon,
	cancelText,
	onCancel,
	onOk,
	okProps,
	cancelProps,
	children,
	isShowOkText = true,
	disableBackdropClick,
	disableEscapeKeyDown,
	titleStyle,
	showButtonClose = false,
	onClose,
	image = undefined,
	imageViewBox = '',
	childrenEnhancedTitle,
	eventName,
	...props
}) => {
	const { t } = useTranslation()

	const handleClose = (event, reason) => {
		if (showButtonClose) {
			if (disableBackdropClick && reason === 'backdropClick') {
				return false
			}

			if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
				return false
			}

			if (typeof onClose === 'function') {
				onClose()
			}
		}
	}

	useEffect(
		() => {
			props?.open && logTrackingScreenEvent(eventName)
		},
		// eslint-disable-next-line
		[props?.open]
	)

	return (
		<Dialog onClose={showButtonClose ? handleClose : false} {...props} css={notifyDialogStyle}>
			<Box
				margin="24px"
				maxWidth={props.boxMaxWidth ? props.boxMaxWidth : 'min-content'}
				{...(props.boxMinWidth ? { minWidth: props.boxMinWidth } : {})}
			>
				<div style={{ display: 'flex' }}>
					{childrenEnhancedTitle}
					{!hideTitle && (
						<Typography
							css={[
								appStore.getTheme.typography.h6,
								{
									color: appStore.getTheme.colors.primary.main,
									width: '95%',
									...titleStyle,
								},
							]}
						>
							{t(title)}
						</Typography>
					)}
				</div>

				{showButtonClose && (
					<div
						onClick={handleClose}
						style={{
							position: 'absolute',
							top: 15,
							right: 15,
							cursor: 'pointer',
						}}
					>
						<SvgIcon component={CloseIconDialog} />
					</div>
				)}

				{!isEmpty(image) && (
					<Box
						style={{ display: 'flex', justifyContent: 'center' }}
						marginTop={3}
						marginBottom={5}
						className="content-notify"
					>
						<SvgIcon style={{ fontSize: '140px' }} viewBox={imageViewBox} component={image} />
					</Box>
				)}

				<Box marginTop={2} marginBottom={3} className="content-notify">
					{children}
				</Box>
				<Grid container spacing={2}>
					{onCancel && (
						<Grid item xs={6}>
							<Button variant="outlined" color="primary" onClick={onCancel} {...cancelProps}>
								{t(cancelText)}
							</Button>
						</Grid>
					)}
					{isShowOkText && (
						<Grid item xs={onCancel ? 6 : 12}>
							<Button color={'primary'} onClick={onOk} {...okProps}>
								{okIcon || t(okText)}
							</Button>
						</Grid>
					)}
				</Grid>
			</Box>
		</Dialog>
	)
}

NotifyDialog.defaultProps = {
	open: true,
	okText: 'OK',
	cancelText: 'CANCEL',
	title: 'NOTIFICATION',
}
