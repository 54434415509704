import gql from 'graphql-tag'
import { JOB_ASSIGMENT_FRAGMENT } from '../apollo.fragment'

export const APPLY_JOB_MUTATION = gql`
	mutation CreateJobApplicant($jobId: String!) {
		createJobApplicant(jobId: $jobId, source: opus_web) {
			...jobAssigmentFragment
		}
	}
	${JOB_ASSIGMENT_FRAGMENT}
`
