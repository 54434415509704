import { gql } from '@apollo/client'
import {
	IMAGE_FILE_FRAGMENT,
	WORK_EXPERIENCE_FRAGMENT,
	WORK_EXPERIENCE_OVERVIEW_FRAMENT,
	WORKED_TIMESHEET_FRAGMENT,
	WORKER_ADDRESS_FRAGMENT,
	WORKER_CERTIFICATION_FRAGMENT,
	WORKER_CERTIFICATION_PROFILE_FRAGMENT,
	WORKER_CREDENTIAL_FRAGMENT,
	WORKER_FRAGMENT,
	WORKER_LINCENSE_FRAGMENT,
	WORKER_REFERENCE_FRAGMENT,
} from '../apollo.fragment'

export const VERIFY_WORKER_QUERY = gql`
	query verifyWorker($token: String!) {
		workerToken(token: $token) {
			token
			notificationChannel
			worker {
				...workerFragment
				recruiter {
					id
					name
					phone
					email
					title
				}
				company {
					address
					id
					country
					phone
				}
			}
		}
	}

	${WORKER_FRAGMENT}
`

export const GET_WORKER_DETAIL_QUERY = gql`
	query getWorkerDetailQuery($id: String!) {
		worker(id: $id) {
			...workerFragment
			workExperienceOverview {
				...workExperienceOverview
			}
			workExperiences {
				...workExperienceFragment
			}
			workerCertifications {
				...workerCertificationFragment
			}
			workerAddress {
				...workerAddressFragment
			}
			supportDocuments {
				id
				documentType
				document {
					id
					contentType
					fileUrl
					filename
					thumbnails
					blobId
					imageUrls(size: ["100x100", "200x200", "500x500"])
				}
			}
			workerReferences {
				...workerReferenceFragment
			}
			workingPreferredLocations {
				id
				preferredWorkingState
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORK_EXPERIENCE_FRAGMENT}
	${WORKER_CERTIFICATION_FRAGMENT}
	${WORK_EXPERIENCE_OVERVIEW_FRAMENT}
	${WORKER_ADDRESS_FRAGMENT}
	${WORKER_REFERENCE_FRAGMENT}
`

export const GET_WORKER_BASIC_INFO_QUERY = gql`
	query getWorkerBasicInfoStoreQuery {
		worker {
			id
			firstName
			lastName
			email
			phone
			phoneVerifiedAt
			workAuthorizedAt
			workingPreferredLocations {
				id
				city
				state
				isPrimary
			}
			workingAuthorization {
				dateOfBirth
				npi
			}
			workerAvailabilities {
				id
				fromDatetime
				toDatetime
				status
			}
			addresses {
				id
				aptNumber
				city
				country
				default
				street
				state
				zipcode
			}
			workerDisciplineSpecialties {
				id
				completedAllScl
				hasSkillChecklist
				discipline {
					disciplineName
					disciplineType
					id
					hasSkillChecklist
				}
				specialty {
					name
					id
					hasSkillChecklist
				}
				isPrimary
			}
		}
	}
`

export const GET_WORKER_ONBOARDING_INFO = gql`
	query getWorkerOnboardingInfoQuery {
		workerOnboarding {
			id
			state
			completedPercent
			onboardingSteps {
				id
				state
				stepName
				completedPercent
				optional
				stepInfo {
					id
					description
					stepOrder
					optional
				}
			}
		}
	}
`

export const GET_REMAINING_OTP_REQUESTS_QUERY = gql`
	query checkRemainingOtpRequestsQuery {
		checkRemainingOtpRequests {
			remainingSessionResendCode
			remainingTotalResendCode
		}
	}
`

export const GET_PROFILE_BASIC_INFO_QUERY = gql`
	query getProfileBasicInfoQuery {
		worker {
			id
			firstName
			lastName
			email
			phone
			phoneVerifiedAt
			userNote
			workAuthorizedAt
			notificationChannels {
				id
				channel
				userAgent
			}
			workingPreferredLocations {
				id
				city
				state
				isPrimary
			}
			workingAuthorization {
				dateOfBirth
				npi
			}
			workerAvailabilities {
				id
				fromDatetime
				toDatetime
				status
			}
			addresses {
				id
				aptNumber
				city
				country
				default
				street
				state
				zipcode
			}
			resumes {
				id
				file {
					id
					fileUrl
					filename
					contentType
				}
				note
			}

			workerDisciplineSpecialties {
				id
				completedAllScl
				discipline {
					disciplineName
					disciplineType
					id
				}
				specialty {
					name
					id
					hasSkillChecklist
				}
			}
		}
	}
`

export const GET_PROFILE_PHONE_VERIFIED_QUERY = gql`
	query getProfileQuery {
		worker {
			phoneVerifiedAt
			phone
		}
	}
`

export const GET_EMERGENCY_CONTACTS_QUERY = gql`
	query getProfileQuery {
		worker {
			workerEmergencyContacts {
				id
				firstName
				lastName
				phone
				relationship
			}
		}
	}
`

export const GET_PRE_SCREEN_INFO_QUERY = gql`
	query getProfileQuery {
		worker {
			id
			availableStartDate
			preferredContract
			preferredShifts
			preferredWorkTypes
			otherPreferredWorkTypes
			recentJobTitle
			totalExperienceInMonths
			workingPreferredLocations {
				id
				city
				state
				isPrimary
			}
		}
	}
`

export const GET_WORKER_DETAIL_FOR_TIMECARDS_QUERY = gql`
	query getWorkerDetailTimeQuery($id: String!) {
		worker(id: $id) {
			...workerFragment
			workedTimesheets {
				...workedTimesheetFragment
				timesheetImages {
					imageUrls(size: ["100x100", "200x200"])
					...imageFileFragment
				}
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_CREDENTIALING_URL_QUERY = gql`
	query getWorkerDetailCredentialingQuery($id: String!) {
		worker(id: $id) {
			id
			phone
			phoneVerifiedAt
		}
	}
`

export const GET_LINSENSE_CERTIFICATION = gql`
	query getWorkerCeritifcationLincense {
		worker {
			id
			workerCertifications {
				...workerCertificationFragment
			}
			workerLicenses {
				...workerLicenseFragment
			}
		}
	}
	${WORKER_CERTIFICATION_PROFILE_FRAGMENT}
	${WORKER_LINCENSE_FRAGMENT}
`

export const GET_WORKER_CERTIFICATIONS_QUERY = gql`
	query getWorkerCertificationsQuery($id: String!) {
		workerCertifications(workerId: $id) {
			...workerCertificationFragment
			licenseImages {
				...imageFileFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`
export const GET_UNIT_TYPE = gql`
	query getUnitType {
		disciplineSpecialties
	}
`

export const GET_WORKER_CREDENTIALS_QUERY = gql`
	query getWorkerCredentialsQuery(
		$filter: WorkerCredentialStatusEnum
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		workerCredentials(filter: $filter, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerCredentialFragment
			}
		}
	}

	${WORKER_CREDENTIAL_FRAGMENT}
`
export const GET_WORKER_SOCIAL_QUERY = gql`
	query getWorkerSocialQuery {
		workerSocialAuthentications {
			providerName
			id
			providerUuid
			email
		}
	}
`
export const GET_WORKER_PARTIAL_SETTINGS_QUERY = gql`
	query getWorkerPartialSettings($workerId: String!) {
		appInit(workerId: $workerId) {
			worker {
				id
				email
			}
		}
	}
`

export const GET_SIGNED_URLS = gql`
	query getSignedUrls($blobIds: [String!]!) {
		signedUrls(blobIds: $blobIds) {
			id
			fileUrl
			blobId
			imageUrls(size: ["100x100", "200x200", "500x500"])
		}
	}
`

export const GET_RECRUITER_INFO = gql`
	query getRecruiterInfo($workerId: String!) {
		worker(id: $workerId) {
			recruiter {
				companyId
				email
				id
				name
				phone
				title
			}
		}
	}
`

export const GET_MASTER_DATA = gql`
	query getMasterData {
		appInit {
			masterData
		}
	}
`

export const GET_AUTHORIZED_WORKER = gql`
	query getAuthorizedWorker($workerId: String!) {
		worker(id: $workerId) {
			workAuthorized
			phone
			lastName
			firstName
			id
			phoneVerifiedAt
		}
	}
`
export const GET_WORKER_AVATAR = gql`
	query getProfileQuery {
		worker {
			avatar {
				file {
					id
					thumbnails
				}
			}
		}
	}
`

export const WORKER_ONBOARDING_QUERY = gql`
	query workerOnboardingQuery {
		workerOnboarding {
			id
			onboardingSteps {
				id
				note
				completedPercent
				stepName
			}
			termsOfService {
				id
				content
				description
				acceptTerms
			}
		}
	}
`

export const ACCEPTED_TERMS_OF_SERVICE_QUERY = gql`
	query acceptedOnboardingTermsOfServiceQuery {
		acceptedOnboardingTermsOfService {
			id
			createdAt
			termsOfService {
				id
				description
				content
				acceptTerms
			}
		}
	}
`

export const GET_SHORT_BASIC_INFO_WORKER = gql`
	query getProfileQuery {
		worker {
			userNote
			workerCertifications {
				id
			}
			workerReferences {
				id
			}
			workerLicenses {
				id
			}
			resumes {
				id
			}
			workingAuthorization {
				dateOfBirth
			}
			workerAvailabilities {
				id
				fromDatetime
				toDatetime
				status
			}
		}
	}
`

export const GET_WORKER_SKILL_CHECKLISTS_QUERY = gql`
	query workerSkillChecklistsQuery($targetType: SkillChecklistCategoryEnum!, $targetId: String!) {
		skillChecklists(targetType: $targetType, targetId: $targetId) {
			id
			name
			workerSkillChecklist {
				id
				assignmentUrl
				completed
				completedAt
				expirationAt
			}
		}
	}
`

export const GET_DISCIPLINES_SPECIALTIES_QUERY = gql`
	query getProfileQuery {
		worker {
			sclProvider {
				provider
				providerId
			}
			workerDisciplineSpecialties {
				id
				completedAllScl
				hasSkillChecklist
				discipline {
					disciplineName
					disciplineType
					id
					hasSkillChecklist
				}
				specialty {
					name
					id
					hasSkillChecklist
				}
				isPrimary
			}
		}
	}
`

export const GET_WORKER_SKILL_CHECKLISTS = gql`
	query getWorkerSkillChecklists($after: String, $before: String, $first: Int, $last: Int) {
		workerSkillChecklists(after: $after, before: $before, first: $first, last: $last) {
			nodes {
				id
			}
			totalCount
		}
	}
`
export const GET_CREDENTAIL_BE_SUBMITTION_QUERY = gql`
	query getCredentialBeSubmittionQuery {
		workerCredentials {
			id
			isLicense
		}
	}
`
export const GET_CAMPAIGNS_QUERY = gql`
	query getCampaigns {
		campaigns {
			id
			title
			subTitle
			content
			startAt
			expiredAt
			active
			banner {
				id
				fileUrl
			}
			workerCampaign {
				id
				mobileDismissAt
				webDismissAt
			}
		}
	}
`

export const GET_CAMPAIGN_WOKER_DETAIL_QUERY = gql`
	query getCampaignWorkerDetailQuery($id: String!) {
		campaign(id: $id) {
			id
			title
			subTitle
			content
			startAt
			expiredAt
			active
			banner {
				id
				fileUrl
			}
			workerCampaign {
				id
				mobileDismissAt
				webDismissAt
			}
		}
	}
`
