import { gql } from '@apollo/client'

export const NEW_FEED_CATEGORY_LIST = gql`
	query {
		feedCategories {
			nodes {
				id
				categoryName
			}
			totalCount
		}
	}
`

export const NEW_FEED_LIST = gql`
	query($after: String, $filter: FeedFilter) {
		feeds(after: $after, filter: $filter, first: 10) {
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				content
				title
				commentsCount
				likesCount
				sharedCount
				isLiked
				categoryId
				publishedAt
				tags {
					id
					name
				}
				images {
					id
					fileUrl
				}
				category {
					id
					categoryName
				}
			}
			totalCount
		}
	}
`

export const NEW_FEED_DETAIL = gql`
	query($id: String!) {
		feed(id: $id) {
			id
			content
			title
			commentsCount
			likesCount
			sharedCount
			isLiked
			categoryId
			publishedAt
			tags {
				id
				name
			}
			images {
				id
				fileUrl
			}
			category {
				id
				categoryName
			}
		}
	}
`

export const NEW_FEED_COMMENT_LIST = gql`
	query($after: String, $feedId: String!, $first: Int) {
		feedComments(after: $after, feedId: $feedId, first: $first) {
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				content
				createdAt
				owner {
					id
					firstName
					lastName
					avatar {
						file {
							id
							fileUrl
						}
					}
				}
			}
			totalCount
		}
	}
`

export const CREATE_FEED_COMMENT = gql`
	mutation($feedId: String!, $content: String) {
		createFeedComment(feedId: $feedId, content: $content) {
			id
			content
			likesCount
			createdAt
			owner {
				id
				firstName
				lastName
				avatar {
					file {
						id
						fileUrl
					}
				}
			}
			resource {
				commentsCount
			}
		}
	}
`

export const DESTROY_FEED_COMMENT = gql`
	mutation($id: String!) {
		destroyFeedComment(id: $id) {
			success
			feedCommentsCount
		}
	}
`

export const LIKE_UNLIKE_FEED = gql`
	mutation($feedId: String!, $action: LikeFeedActionsEnum!) {
		likeOrUnlikeFeed(feedId: $feedId, action: $action) {
			id
			isLiked
			likesCount
		}
	}
`

export const SHARE_FEED = gql`
	mutation($feedId: String!) {
		shareFeed(feedId: $feedId) {
			id
			sharedCount
		}
	}
`
