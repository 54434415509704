import {
	CREATE_WORKER_MUTATION,
	GET_CURRENT_WORKER_INFO,
	REFRESH_WORKER_TOKEN,
	RESET_PASSWORD_FOR_WORKER_MUTATION,
	SEND_PASSWORD_RESET_WORKER_MUTATION,
	SIGNIN_WORKER_MUTATION,
	apolloClient,
} from '~/common/apollo'
import {
	IAuthService,
	IAuthCheckParams,
	IAuthForgotPasswordParams,
	IAuthGetIdentityParams,
	IAuthLoginParams,
	IAuthLogoutParams,
	IAuthRefreshParams,
	IAuthRegisterParams,
	IAuthUpdatePasswordParams,
} from '~/core/interfaces'
import localforage from 'localforage'
import { isEmpty } from 'lodash'

const AUTH = {
	accessToken: 'access_token',
	refreshToken: 'refresh_token',
}

const CONTEXT = {
	clientName: 'public',
}

export class AuthService implements IAuthService {
	async login(params?: IAuthLoginParams) {
		const variables = params || {}
		const {
			data: {
				signUpWorker: { authToken, worker, notificationChannel },
			},
		} = await apolloClient.mutate({ mutation: SIGNIN_WORKER_MUTATION, variables, context: CONTEXT })

		await localforage.setItem(AUTH.accessToken, authToken.accessToken)
		await localforage.setItem(AUTH.refreshToken, authToken.refreshToken)

		return { success: true, auth: authToken, worker, notificationChannel }
	}

	async logout(params?: IAuthLogoutParams) {
		await localforage.removeItem(AUTH.accessToken)
		await localforage.removeItem(AUTH.refreshToken)

		return { success: true }
	}

	async check(params?: IAuthCheckParams) {
		const token = localforage.getItem(AUTH.accessToken)

		return {
			success: !!token,
		}
	}
	async refresh(params: IAuthRefreshParams) {
		const refreshToken = await localforage.getItem(AUTH.refreshToken)
		const variables = { refreshToken }
		const {
			data: {
				refreshToken: { authToken },
			},
		} = await apolloClient.mutate({ mutation: REFRESH_WORKER_TOKEN, variables })

		localforage.setItem(AUTH.accessToken, authToken.accessToken)
		localforage.setItem(AUTH.refreshToken, authToken.refreshToken)

		return { auth: authToken }
	}

	async register(params?: IAuthRegisterParams) {
		let workerDisciplineSpecialties = [
			{
				disciplineId: params?.disciplineId,
				specialtyId: '',
			},
		]

		if (!isEmpty(params?.specialtyId)) {
			workerDisciplineSpecialties = params?.specialtyId.map((item: string) => ({
				disciplineId: params?.disciplineId,
				specialtyId: item,
			}))
		}
		const variables = { ...params, workerDisciplineSpecialties }

		const {
			data: {
				signUpWorker: { authToken, ...rest },
			},
		} = await apolloClient.mutate({ mutation: CREATE_WORKER_MUTATION, variables, context: CONTEXT })

		return { success: true, auth: authToken, ...rest }
	}
	async forgotPassword(params?: IAuthForgotPasswordParams) {
		const variables = params || {}
		const {
			data: {
				sendPasswordResetWorker: { success },
			},
		} = await apolloClient.mutate({ mutation: SEND_PASSWORD_RESET_WORKER_MUTATION, variables, context: CONTEXT })

		return { success }
	}
	async updatePassword(params?: IAuthUpdatePasswordParams) {
		const variables = params || {}

		const {
			data: {
				resetPasswordForWorker: { success },
			},
		} = await apolloClient.mutate({ mutation: RESET_PASSWORD_FOR_WORKER_MUTATION, variables, context: CONTEXT })

		return { success }
	}

	async getIdentity(params?: IAuthGetIdentityParams) {
		const variables = params || {}

		const {
			data: {
				appInit: { worker },
			},
		} = await apolloClient.query({ query: GET_CURRENT_WORKER_INFO, variables })

		return worker
	}

	async onError(error?: Error) {
		console.log('🚀 ~ file: AuthService.ts:127 ~ AuthService ~ onError ~ error:', error)
		return Promise.resolve({})
	}
}

export const authService = new AuthService()
