const moment = require('moment-timezone')

export function getFormatDateTime(timestamp, timezone) {
	if (timestamp) {
		const timezoneConvert = moment.tz(timestamp, timezone)
		const formattedDate = timezoneConvert.format('dddd, MMM D, YYYY')
		return formattedDate
	}
}

export function getFormatTimedata(startTime, endTime, timezone) {
	if (startTime && endTime) {
		// Parse the timestamps and set the timezone
		const start = moment.tz(startTime, timezone)
		const end = moment.tz(endTime, timezone)
		// Format the output string
		const formattedStartTime = start.format('h:mmA')
		const formattedEndTime = end.format('h:mmA')

		return `${formattedStartTime}-${formattedEndTime}`
	}

	return null // Handle case where either startTime or endTime is missing
}

export function getFormatTime(timestamp, timezone) {
	if (timestamp) {
		const timezoneConvert = moment.tz(timestamp, timezone)
		const formattedTime = timezoneConvert.format('hh:mm a')
		return formattedTime
	}
}
export function calculateDuration(startTime, endTime) {
	if (startTime && endTime) {
		const duration = moment.duration(moment(endTime).diff(moment(startTime)))

		const hours = Math.floor(duration.asHours())
		const minutes = Math.floor(duration.asMinutes()) % 60

		const formattedDuration = `${hours}:${minutes.toString().padStart(2, '0')}`
		return formattedDuration
	}
}
export const formatDatetimePerdiem = (datetime) => {
	if (datetime) {
		return moment(datetime).format('HH:mm')
	}
}

export const formatDatePerdiem = (datetime) => {
	if (datetime) {
		return moment(datetime).format('MMM D, YYYY')
	}
}
export const formatDateTravel = (datetime) => {
	if (datetime) {
		return moment(datetime).format('MMM D, YYYY')
	}
}
