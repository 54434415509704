import { includes } from 'lodash'
import { apolloClient, GET_BULLETINS_QUERY } from '~/common/apollo'
import { action, observable, store } from '~/common/mobx.decorator'
import { careHomeStore } from '~/features/care-home/care-home.store'
import { authStore } from '~/stores'

@store()
class GuidanceStore {
	@observable bulletins = []
	@observable showDialog = false
	@observable showDialogBeSubmittion = false

	@action
	fetchGuidances = async () => {
		try {
			const visibleBulletinKinds = ['submission_ready', 'welcome', 'referral', 'resource', 'pillar']
			const response = await apolloClient.query({
				query: GET_BULLETINS_QUERY,
			})
			this.bulletins = response?.data?.bulletins.filter(({ kind }) => includes(visibleBulletinKinds, kind))
		} catch (error) {
			console.error(error)
		}
	}

	@action
	setShowDialog = async (value) => {
		this.showDialog = value
	}

	@action
	setShowDialogBeSubmittion = async (value) => {
		this.showDialogBeSubmittion = value
	}
	@action
	toggleBeReadySubmissionDialog = async () => {
		await new Promise((resolve) => setTimeout(resolve, 3000)) //Delay 2s to wait for bulletins to be fetched

		const bulletin = this.bulletins.find((bulletin) => bulletin.kind === 'submission_ready')

		if (!bulletin) {
			return
		}

		if (!authStore.activated) {
			careHomeStore.openBeReadyDialog()
		}
	}
}

export const guidanceStore = new GuidanceStore()
