import axios from 'axios'
import { flatMap, isEmpty } from 'lodash'
import { MASTER_DATA_FIELDS } from '~/common/constants'
import { action, computed, observable, persist, store } from '~/common/mobx.decorator'
import { apolloClient, GET_MASTER_DATA, GET_SPECIALTY_MASTER_QUERY, LIKE_DISLIKE_JOB_MUTATION } from '~/common/apollo'
import { authStore, notifyStore } from '~/stores'
import { redirectEventTrackingBookMark } from '~/common/helpers/bookMark.helper'
import { mappingObjectToArrayField } from '~/common/helpers/mapping.helper'
import { careStore } from '~/companies/care/care.store'
import { parsePathname } from '~/common/helpers/url.helper'

const MIN_PATH_JOBS_DETAIL = 2
const MAX_PATH_JOBS_DETAIL = 8

@store()
class MasterStore {
	@observable disciplines
	@observable disciplinesData
	@observable disciplinesChip = []
	@observable referrals
	@observable agencies
	@observable chartings
	@observable settings
	@observable primarySpecialities
	@observable secondarySpecialities
	@persist @observable licenseStates = []
	@observable referralRelationships
	@observable jobDisciplines
	@observable jobShifts
	@observable unitType = null
	@observable originalSpecialties = {}
	@observable specialties = []
	@observable reasons = []
	@observable locations = []
	@observable salutations = []
	@observable relationships = []
	@observable timezoneIdentifiers = []
	@observable documentTypes = []
	@observable facilityTypes = []
	@observable facilityTypesSettings = []
	@observable contactRelationships = []
	@observable educationDegrees = []
	@observable referenceRelationship = []
	@observable contractLengths = []
	@observable referredRelationships = []
	@observable workLocations = []
	@observable originalLocations = []
	@observable specialtyMasters = []
	@observable existingSpecialty = false
	@observable assignmentTypes = []
	@observable referenceTypes = []
	@observable cityStates = []
	@observable cityStatesCombined = []
	@observable workerCertifications = []
	@observable isJobDetailPage = false
	@persist @observable specialtyOptions = []
	@persist @observable listSpecialtyName = []
	@persist @observable workTypes
	@persist @observable masterData = {}
	@persist @observable frequencyMasterData = {}
	@persist @observable alertChannelMasterData = {}
	@persist @observable pauseAlertPeriodMasterData = {}

	@computed
	get licenseSpecialtyOptions() {
		const specialties = flatMap(this.disciplinesData, 'specialties') || []
		return specialties.map((item) => ({
			value: item?.id || item?.name,
			label: item?.name,
			parentValue: item?.parentValue,
		}))
	}

	@action
	fetchDisciplines = async (force = false) => {
		if (!force && !isEmpty(this.disciplinesData)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.discipline,
			},
		})
		this.disciplinesData = response.data.map((item) => ({
			label: item.name,
			value: item.id,
			specialties: item?.specialties?.map((specialty) => ({ ...specialty, parentValue: item.id })) || [],
		}))
		this.disciplinesChip = response.data.map((item) => ({
			label: item.name,
			value: item.id,
		}))
	}

	@action
	fetchTypeJobs = async (force = false) => {
		if (!force && !isEmpty(this.disciplines)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.workTypes,
			},
		})
		this.workTypes = response.data.map((item) => ({
			label: item.name,
			value: item.name,
			media: item.media,
			media_url: item.media?.url,
		}))
	}
	@action
	fetchTimezoneIdentifiers = async () => {
		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.timezoneIdentifiers,
			},
		})
		this.timezoneIdentifiers = response.data.map((item) => ({
			label: item.abbreviation,
			value: item.name,
		}))
	}

	@action
	fetchReferenceRelationship = async (force = false) => {
		if (!force && !isEmpty(this.referenceRelationship)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.referenceRelationship,
			},
		})
		this.referenceRelationship = response.data.map((item) => {
			return {
				label: item.name,
				value: item.id,
			}
		})
	}

	@action
	fetchEducationDegrees = async (force = false) => {
		if (!force && !isEmpty(this.educationDegrees)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.educationDegrees,
			},
		})
		this.educationDegrees = response.data.map((item) => {
			return {
				label: item.name,
				value: item.name,
			}
		})
	}

	@action
	fetchFacilityTypesSettings = async (force = false) => {
		if (!force && !isEmpty(this.disciplines)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.facilityTypes,
			},
		})
		this.facilityTypesSettings = response.data.map((item) => ({
			label: item?.name,
			value: item?.name,
		}))
	}

	@action
	fetchJobDisciplines = async (force = false) => {
		if (!force && !isEmpty(this.jobDisciplines)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: 'specialties',
			},
		})

		this.disciplines = response.data.map((item) => ({
			label: item.long_name,
			value: item.name,
			id: item.id,
		}))

		this.specialtyOptions = Object.entries(response.data).map(([key, value]) => ({
			label: key,
			value: key,
			child: Object.entries(value).map(([keyChild, valueChild]) => {
				return {
					label: valueChild.long_name,
					value: valueChild.skill_name,
					parent: key,
				}
			}),
		}))

		this.listSpecialtyName = Object.entries(response.data)
			.map(([key, value]) => ({
				label: key,
				value: key,
				child: Object.entries(value).map(([key, value]) => {
					return {
						label: value.long_name,
						value: value.skill_name,
					}
				}),
			}))
			.map((item) => item.child)
			.flat(1)

		this.jobDisciplines = response.data
	}

	@action
	fetchJobShifts = async (force = false) => {
		if (!force && !isEmpty(this.jobShifts)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: 'shifts',
			},
		})
		this.jobShifts = response.data?.map((item) => ({ label: item.name, value: item.name }))
	}

	@action
	fetchLocations = async (force = false) => {
		if (!force && !isEmpty(this.locations)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.preferredLocations,
			},
		})

		this.locations = response.data
	}

	@action
	fetchReferralRelationships = async (force = false) => {
		if (!force && !isEmpty(this.referralRelationships)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.referralRelationship,
			},
		})

		this.referralRelationships = response.data
	}

	@action
	fetchTravelerRelationship = async (force = false) => {
		if (!force && !isEmpty(this.referredRelationships)) {
			return
		}
		const response = await axios.get(`/company_configs/master_data`)

		const relationships = response?.data?.referred_traveler?.relationship
		let data = Object.keys(relationships).map((key) => ({
			label: relationships[key],
			value: key,
		}))

		this.referredRelationships = data
	}

	@action
	fetchContactRelationship = async (force = false) => {
		if (!force && !isEmpty(this.contactRelationships)) {
			return
		}
		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.contactRelationship,
			},
		})

		if (!response?.data) {
			return
		}
		this.contactRelationships = response.data.map((item) => ({ label: item.name, value: item.name }))
	}

	@action
	fetchReferenceType = async (force = false) => {
		if (!force && !isEmpty(this.referenceTypes)) {
			return
		}
		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.referenceType,
			},
		})

		if (!response?.data) {
			return
		}
		this.referenceTypes = response.data.map((item) => ({ label: item.id, value: item.name }))
	}

	@action
	fetchContractLength = async (force = false) => {
		if (!force && !isEmpty(this.contractLengths)) {
			return
		}
		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.contractLengths,
			},
		})

		if (!response?.data) {
			return
		}
		this.contractLengths = response.data.map((item) => ({ label: item.id, value: item.name }))
	}

	@action
	fetchReferrals = async (force = false) => {
		if (!force && !isEmpty(this.referrals)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.referral,
			},
		})

		this.referrals = response.data
	}

	@action
	fetchAgencies = async (force = false) => {
		if (!force && !isEmpty(this.agencies)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.agency,
			},
		})

		this.agencies = response.data
	}

	@action
	fetchChartings = async (force = false) => {
		if (!force && !isEmpty(this.chartings)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.charting,
			},
		})

		this.chartings = response.data
	}

	@action
	fetchSecondarySpecialities = async (force = false) => {
		if (!force && !isEmpty(this.secondarySpecialities)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.secondarySpecialty,
			},
		})

		this.secondarySpecialities = response.data
	}

	@action
	fetchLicenseStates = async (force = false) => {
		if (!force && !isEmpty(this.licenseStates)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.licenseState,
			},
		})
		this.licenseStates = response.data.map((item) => ({ label: item?.name, value: item?.state_code }))
	}

	@action
	fetchSettings = async (force = false) => {
		if (!force && !isEmpty(this.settings)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.settings,
			},
		})
		this.settings = response.data.map((item) => ({ label: item?.name, value: item?.name }))
	}

	@action
	fetchPrimarySpecialities = async (force = false) => {
		if (!force && !isEmpty(this.primarySpecialities)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.primarySpecialty,
			},
		})

		this.primarySpecialities = response.data
	}

	@action
	onFavoriteJob = async (values) => {
		const { jobId, action } = values?.variables
		try {
			const response = await apolloClient.mutate({
				mutation: LIKE_DISLIKE_JOB_MUTATION,
				variables: {
					jobId: jobId,
					action: action,
				},
			})
			notifyStore.success('$MESSAGES.SUCCESSFUL')
			return response?.data?.likeOrDislikeAJob
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	onTrackingFavorite = async (jobId, flag, finalizeData) => {
		if (finalizeData?.type) {
			if (finalizeData?.status === 'EVENT_ORDER_CITY') {
				await redirectEventTrackingBookMark(finalizeData.type, {
					job_id: jobId,
					is_bookmarked: !flag,
					worker_email: authStore.workerEmail,
					order_number: finalizeData.order,
					city_id: finalizeData.cityId,
				})
			} else if (finalizeData?.status === 'EVENT_ORDER') {
				await redirectEventTrackingBookMark(finalizeData.type, {
					job_id: jobId,
					is_bookmarked: !flag,
					worker_email: authStore.workerEmail,
					order_number: finalizeData.order,
				})
			} else {
				await redirectEventTrackingBookMark(finalizeData.type, {
					job_id: jobId,
					is_bookmarked: !flag,
					worker_email: authStore.workerEmail,
				})
			}
		}
	}

	@action
	fetchRelationships = async (force = false) => {
		if (!force && !isEmpty(this.relationships)) {
			return
		}

		const response = await axios.get(`/company_configs/master_data`)
		this.relationships = response?.data?.worker_reference?.relationship
	}

	@action
	fetchSalutations = async (force = false) => {
		if (!force && !isEmpty(this.salutations)) {
			return
		}

		const response = await axios.get(`/company_configs/master_data`)
		this.salutations = response?.data?.worker_reference?.salutation
	}

	@action
	fetchReasonLeavings = async (force = false) => {
		if (!force && !isEmpty(this.reasons)) {
			return
		}
		const response = await axios.get(`/company_configs/master_data`)
		this.reasons = response?.data?.work_experience?.reason_for_leaving
	}

	@action
	fetchAssignmentTypes = async (force = false) => {
		if (!force && !isEmpty(this.assignmentTypes)) {
			return
		}
		const response = await axios.get(`/company_configs/master_data`)
		this.assignmentTypes = response?.data?.work_experience?.assignment_type
	}

	@action
	fetchSpecialties = async (force = false) => {
		if (!force && !isEmpty(this.specialties)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.specialty,
			},
		})

		this.originalSpecialties = response.data
		let data = []
		Object.values(response.data).map((item, index) => {
			let dataArray = Object.values(item)
			const category = Object.keys(response.data)[index]
			dataArray = dataArray.map((item) => ({ ...item, category: category }))
			data = [...data, ...dataArray]
			return data
		})

		this.specialties = data
	}

	@action
	fetchSupportDocumentTypes = async (force = false) => {
		if (!force && !isEmpty(this.documentTypes)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.documentType,
			},
		})

		this.documentTypes = response.data
	}

	@action
	fetchFacilityTypes = async (force = false) => {
		if (!force && !isEmpty(this.facilityTypes)) {
			return
		}
		const response = await axios.get(`/company_configs/master_data`)
		this.facilityTypes = response?.data?.work_experience?.facility_type
	}

	@action
	fetchWorkLocations = async (force = false) => {
		if (!force && !isEmpty(this.workLocations)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.state,
			},
		})

		const locations = response?.data

		this.originalLocations = response?.data
		let data = Object.keys(locations).map((key) => ({
			label: locations[key],
			value: key,
		}))

		this.workLocations = data
	}

	@computed
	get checkIsJobDetailPage() {
		return this.existingSpecialty && careStore.getActiveFeature()?.code === 'jobs'
	}

	@action
	fetchCityState = async (force = false, input = '') => {
		if (!force && !isEmpty(this.cityStates)) {
			return
		}

		const response = await axios.get(`/cities?name=${input}`)

		if (!input.length && isEmpty(this.originalLocations)) {
			this.originalLocations = response?.data
		}

		this.cityStates = response?.data.map((item) => ({ label: item.name, value: item.name }))
		this.cityStatesCombined = response?.data.map((item) => ({
			label: `${item.name}${item.state_code ? `, ${item.state_code}` : ''}`,
			value: `${item.name}${item.state_code ? `, ${item.state_code}` : ''}`,
		}))
	}
	@action
	fetchMasterData = async () => {
		const response = await apolloClient.query({
			query: GET_MASTER_DATA,
		})
		this.masterData = response.data.appInit.masterData
		this.frequencyMasterData = mappingObjectToArrayField(this.masterData.save_filter.frequency)
		this.alertChannelMasterData = mappingObjectToArrayField(this.masterData.save_filter.alert_channel)
		this.pauseAlertPeriodMasterData = mappingObjectToArrayField(this.masterData.save_filter.pause_alert_period)
	}

	@action
	fetchSavedFilterFrequencies = async (force = false) => {
		if (!force && !isEmpty(this.savedFilterFrequencies)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.savedFilterFrequencies,
			},
		})
		this.savedFilterFrequencies = response.data.map((item) => ({ label: item?.name, value: item?.id }))
	}
	@action
	fetchSavedFilterPauseAlertPeriods = async (force = false) => {
		if (!force && !isEmpty(this.savedFilterPauseAlertPeriods)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.savedFilterPauseAlertPeriods,
			},
		})
		this.savedFilterPauseAlertPeriods = response.data.map((item) => ({ label: item?.name, value: item?.id }))
	}
	@action
	fetchSavedFilterNotificationChannels = async (force = false) => {
		if (!force && !isEmpty(this.savedFilterNotificationChannels)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.savedFilterNotificationChannels,
			},
		})
		this.savedFilterNotificationChannels = response.data.map((item) => ({ label: item?.name, value: item?.id }))
	}

	@action
	fetchWorkerCertifications = async (force = false) => {
		if (!force && !isEmpty(this.workerCertifications)) {
			return
		}

		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.workerCertifications,
			},
		})
		this.workerCertifications = response.data.map((item) => ({ label: item?.name, value: item?.id }))
	}

	@action
	fetchUnitType = async (force = false) => {
		if (!force && !isEmpty(this.unitType)) {
			return
		}
		const response = await axios.get(`/picklists`, {
			params: {
				picklist_type: 'disciplines',
			},
		})

		if (!response?.data) {
			return
		}

		this.unitType = response.data.reduce((accumulative, currentItem) => {
			const items = currentItem.specialties?.map((specialty) => ({
				label: specialty.name,
				value: `${currentItem.name} - ${specialty.name}`,
				id: specialty.id,
				groupLabel: currentItem.name,
				groupId: currentItem.id,
			}))

			return [...accumulative, ...items]
		}, [])
	}
	@action
	fetchSpecialtyMasters = async () => {
		const response = await apolloClient.query({
			query: GET_SPECIALTY_MASTER_QUERY,
		})
		this.specialtyMasters = response.data.specialtiesMaster
	}
	@action
	getLabelCurrentTimeZone = (nameTimezone) => {
		return this.timezoneIdentifiers.find((item) => item.value === nameTimezone)?.label
	}
	@action
	checkExistingSpecialty = (params) => {
		const { id } = params
		const pathName = window.location.pathname
		const convertedPathName = parsePathname(pathName)
		const specialtyArray = this.specialtyMasters
		this.existingSpecialty = !!specialtyArray.find((item) => item.slug === id)
		if (
			(!this.existingSpecialty && convertedPathName.length === MIN_PATH_JOBS_DETAIL) ||
			convertedPathName.length === MAX_PATH_JOBS_DETAIL
		) {
			this.setIsJobDetailPage(true)
		} else {
			this.setIsJobDetailPage(false)
		}
		return this.existingSpecialty
	}
	@action
	setIsJobDetailPage = (value) => {
		this.isJobDetailPage = value
	}
}

export const masterStore = new MasterStore()
